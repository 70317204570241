export const environment = {
  production: false,
  envName: "Train",
  version: "1.0",
  withSSO: true,
  logging: true,
  popupTimer: 300, //5 minute before
  splunk: {
    URL: "https://api-gateway-test.enterprise.agero.com/ageroappsapi/services/collector/event",
    ApiKey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    splunkEnvAuthKey: "85DC7BC0-73CD-47C5-8EF4-4BF5B3041C7B",
  },
  clientCode: {
    Agero: "AGR",
  },
  API: {
    getSettingsApi:
      "https://api-gateway-test.enterprise.agero.com/ageroappsapi/v1/GetConfigurationSettings",
    apikey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    DPLExistingCasesApi:  "https://api-gateway-test.enterprise.agero.com/ageroappsapi/v1/GetCallbackSearchCaseData",
    getProgramList: "https://api-gateway-test.enterprise.agero.com/ageroappsapi/v1/GetProgramList",
  },
  ageroAuthConfig: {
    clientId: "5k956q566rs71llvhr0obijq3s",
    useMultiTenantApi: true,
    appWebDomain: "login.nonprod.oauth.agero.com",
    redirectUriSignIn: "callback.html", 
    redirectUriSignOut: "logout.html",
    apiFullUrl: "https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1",
    refreshTimeoutOffset: 10 * 60,
    // How many seconds of inactivity before the user is signed out.  If not included, defaults to 30 minutes
    activityTimeout: 30 * 60,
  },
  Star2URL: "https://d7pdjynii1p3z.cloudfront.net",
  DispatchLiteURL: "https://d3u8wdtlnrgurc.cloudfront.net",

  RSADashboardURL: "https://d2izlsqy5psser.cloudfront.net",
  LocateMeURL: "https://d1q98630xeossm.cloudfront.net/locate.html",
  CTI: "https://d1ph11r127vgxk.cloudfront.net/",
  ConfigurationsURL: "https://d1xz76r3u5e3q3.cloudfront.net",
  IVRConfig: "https://d2zp9cb4xq4rdb.cloudfront.net",
  SocketConnectionURL : "wss://7advil46f3.execute-api.us-east-1.amazonaws.com/stage?header="
};
